@import '/styles/_global';
@font-face {font-family: 'Bell MT'; src: url('/font/Bell_MT.ttf') format('truetype'); font-weight: normal; font-style: normal; font-display: swap;}

.textParent{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Bell MT';
    font-size: clamp(10px, 2.4vw, 24px);
}

.row {flex-wrap: nowrap;  white-space: nowrap; overflow: auto; padding-top: 2rem;
    padding-bottom: 45px; -webkit-overflow-scrolling: touch; overflow-scrolling: touch;-ms-overflow-style: none;  scrollbar-width: none;
      -webkit-transform: translateZ(0); transform: translateZ(0);scroll-behavior: smooth; margin: 0 -7px;}

.col-9{
    padding: 0 7px;
}

.teaserProductParent{
    padding-bottom: 3rem;
}

.carouselHeader{
  padding-top: 2rem;
  padding-bottom: 2.5rem;

 div video{
    width: 100%;
    height: 21.25rem;
    aspect-ratio: 16 / 9; 
    object-fit: cover; 
  }
}

.carouselHeader > div{
  justify-content: center !important;
}

.categoryList li{padding: 0px 10px; margin: 0 0.5px; margin-bottom: 15px; width: 17.813vw !important;}

@media (max-width: 992px){
  .textParent{
    font-size: clamp(12px, 4vw, 24px);
    line-height: 2.25rem;
    display: block;
    white-space: normal;
    overflow-wrap: break-word;
  }
  
  .col-9 video{
    width: 100%;
    height: clamp(142px, calc(142px + (426 - 142) * ((100vw - 360px) / (992 - 360))), 426px);
    aspect-ratio: 16 / 9; 
    object-fit: cover; 
  }
}