@import '/styles/_global';
.youthGlowSec {
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
  }
}
