@import '/styles/_global';
.routinecontent {
  h2 {
    font-family: "Bell MT";
    font-size: clamp(4rem,4.65vw,5.625rem);
    color: #222222;
    text-align: center;
  }
  h3 {
    font-size: clamp(2.5rem,3vw,3.75rem);
    text-align: center;
    font-family: 'DINMedium';
    font-weight: 500;
    color: #222222;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  padding-block: 2rem;
}
.addbag {
  text-align: center;
  max-width: 100%;
  
  button {
    background-color: #3e4245 !important;
    border: none !important;
    color: #fff !important;
    text-align: center !important;
    display: inline-block !important;
    margin-inline: auto !important;
    padding-block: 20px !important;
    font-family: "Barlow" !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    max-width: 460px !important;
    transition: background-color 0.3s ease-in-out !important;
  }
}

@media screen and (min-width: 540px) and (max-width: 930px) {
  .routinecontent {
    h2 {
      font-size: 4rem;
    }
    h3 {
      font-size: 2.5rem;
    }
  }
  .wrapper {
    padding-block: 1.5rem;
  }
  .addbag {
    button {
      font-size: 0.9rem;
      padding-block: 15px;
      width: 100%;
      border-radius: 0 !important;
    }
  }
}

@media screen and (min-width: 290px) and (max-width: 539px) {
  .routinecontent {
    h2 {
      font-size: 2.8rem;
    }
    h3 {
      font-size: 1.4rem;
    }
  }
  .wrapper {
    padding-block: 1.5rem;
  }
  .addbag {
     max-width: 100%;
     border-radius: 0px;

     button {
      font-size: 0.9rem;
      padding-block: 15px;
      width: 100%;
      text-align: center;
      border-radius: 0 !important;
    }
  }
}
