@import '/styles/_global';

.productheading{
    color: #3E4245;
    font-size: 2.5rem;
    font-family: "Bell MT";
    font-weight: 400;
    text-align: center;
    margin-top: 1rem;
    padding-top: 1rem;
}

.productinfocontainer{
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 0.5rem;
    margin-top: 1rem;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    .productinfo
    {
      display: flex;
      gap: 0.5rem;
        font-family: "Barlow";
        font-size: 1rem;
        font-weight: 600;
      p{
        color: #3E4245
      }
      span{
        font-family: "Barlow";
        font-weight: 400;
        color: #575757;
        text-align: center;
      }
    }
}

.productInfoParent{
  background: #fff;
}

@media screen and (max-width: 992px)
{
    .productheading{
        font-size: 2.7rem;
        text-align: center;
        
    }

    .productinfocontainer{
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        gap: 1.5rem;
        align-items: center;
        .productinfo
        {
          display: flex;
          flex-direction: column;
          gap: 0;
          align-items: center;
            font-family: "Barlow";
            font-size: 1rem;
            font-weight: 600;
          p{
            color: #3E4245;
            margin: 0;
          }
          span{
            font-family: "Barlow";
            font-weight: 400;
            color: #575757;
          }
        }
    }
}