@import '/styles/_global';

.dateReview{
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.875rem;
    color: #575757;
}

.reviewWrapper{
    margin: 0;
    padding: 0;
    padding-left: clamp(4.5rem,5.2vw,6.25rem);
    padding-right: clamp(4.5rem,5.2vw,6.25rem);
    padding-top: clamp(3.5rem,4vw,5rem);
    padding-bottom: clamp(3.5rem,4vw,5rem);
    background: #fff;
}

.reviewwhead{
    text-align: center;
    font-size: 38px;
    font-family: "Bell MT";
    color: #222222;
}
.review_view_main{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Bell MT";
    font-weight: 400;
    padding: 1rem;
}
.ratingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.8rem 1rem
}

.rating {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.averageRating, .reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
}

.reviews{
    margin-left: 80px;
}
.reviews>p{
    font-size: clamp(2.5rem,3.125vw,3.75rem);
    font-family: "Bell MT";
    font-weight: 400;
    color: #222222;
    margin-bottom: 0px;
}
.reviews>span{
    font-family: "Barlow";
    width: 100%;
    font-weight: 600;
    margin-top: -1.25rem;
    text-align: start;
    font-size: 0.6rem;
}
.starRating{
    display: flex;
    align-items: center;
    font-family: "Bell MT";
    font-weight: 400;
    font-size: clamp(2.5rem,3.125vw,3.75rem);
    gap: 0.3rem;
  }
  .avgtxt{
    font-family: "Barlow";
    width: 100%;
    margin-top: -1.25rem;
    font-weight: 600;
    text-align: start;
    font-size: 0.6rem;
}
    

.actionButton {
    display: flex;
    align-items: center;
    float: right;
}

.actionButton button {
    padding: 0.4rem 1rem;
    border: 1px solid #3e4245;
    color: #222222;
    font-size: 1rem;
    cursor: pointer;
    padding-inline: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: "Barlow";
    font-weight: 600;
     background: #fff;
}

.view_all{
    margin-bottom: 1rem;
}

.view_all>a{
    font-size: 1.125rem;
    border-bottom: 1px solid #222222;
    font-family: 'Bell MT';
    font-weight: 400;
}

.listrapper{
    display: flex;
    font-family: "Bell MT";
    color: #222222;
    gap: 1rem;
    border-top: 1px solid #CCCCCC; 
    padding-top: 2rem;
    margin-top: 2rem;
}
.review_summary{
    width: 100%;
    padding-right: 10px;
}
.name{
    font-family: "Barlow";
    font-weight: 600;
    font-size: 0.875rem;
}
.review_summary>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}
.review_summary>div>h3{
    font-family: "Bell MT";
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
}
.review_ndate{
  display: flex;
  flex-direction: column;
  width: 25%;
  font-size: 0.8rem;
}
.review_text{
    color: #575757;
    font-family: "Barlow";
    font-weight: 400;
    line-height: 21px;
    text-align: justify;
}
 .reviewhelpful{
    background-color: #FFFAF5;
    width: fit-content;
    padding-inline: 20px;
    padding-block: 20px;
}
.reviewhelpful>p{
    font-family: "Barlow";
    font-weight: 600;
    color: #222222;
    margin: 0;
    font-size: 0.8rem;
}
.helpfulbuttons{
    display: flex;
    gap: 1rem;
    font-family: "Barlow";
    font-weight: 600;
    color: #222222;
    align-items: center;
    margin-top: 15px;
}
.thumbsup,.thumbsdown{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.thumbsup>span,.thumbsdown>span{
    font-family: "Barlow";
    font-weight: 400;
    font-size: 10px;
}
.star_imgPdp{
    width: 20px;
    height: 20px;
}
.thumbsupimg, .thumbsdownimg{
    width: 20px;
    height: 20px;
}

@media screen and (min-width: 640px) and (max-width: 992px) {
    .reviewWrapper{
        padding: 1rem;
        background: #fff;
    }
    .reviewwhead{
        text-align: center;
        font-size: 30px;
        font-family: "Bell MT";
        color: #222222;
    }
    .ratingContainer {
        flex-direction: column;
        gap: 1rem;
    }
    .rating {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 0.5rem;
        width: 100%;
    }
    .reviews {
        margin-left: 0;
    }
    .reviews>span {
        font-size: 1.8rem;
    }
    .reviews>p {
        font-size: 6rem;
    }
    .star_imgPdp{
    width: 30px;
    height: 30px;
}
    .starRating {
        font-size: 6rem;
    }
    .avgtxt {
        font-size: 1.8rem;
    }
    .actionButton {
        gap: 1rem;
        width: 100%;
    }
    .actionButton button {
        width: 100%;
        padding: 1rem;
        font-size:2rem;
    }
    .review_ndate{
        font-size: 2rem;
    }
    .name{
        font-size: 2rem;
    }
    .review_text{
        font-size: 2rem;
        margin-top: 0.5rem;
        line-height: 3rem;
    }
  
  .listrapper {
        flex-direction: column;
        gap: 1rem;
    }
    .review_summary>div {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0.5rem;
    }
    .review_summary>div>h3 {
        font-size: 4rem;  
    }
    .reviewhelpful {
        padding-inline: 10px;
        padding-block: 10px;
    }
    .reviewhelpful>p {
        font-size: 1.8rem;
    }
    .helpfulbuttons {
        gap: 0.5rem;
    }
    .thumbsupimg, .thumbsdownimg{
          width: 60px;
          height: 60px;
    }
    .thumbsup,.thumbsdown {
        gap: 0.6rem;
    }
    .thumbsup>span,.thumbsdown>span {
        font-size: 1.8rem;
    }
    .view_all>a{
        font-size: 3rem;
        border-bottom: 1px solid #222222;
    }
    
}

@media screen and (max-width: 639px) {
    .reviewWrapper{
        padding: 1rem;
        background: #fff;
    }
    .reviewwhead{
        text-align: center;
        font-size: 28px;
        font-family: "Bell MT";
        color: #222222;
    }
    .ratingContainer {
        flex-direction: column;
        gap: 1rem;
    }
    .rating {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 0.5rem;
        width: 100%;
    }
    .reviews {
        margin-left: 0;
    }
    .reviews>span {
        font-size: 0.8rem;
    }
    .reviews>p {
        font-size: 3rem;
    }
    .star_imgPdp{
    width: 23px;
    height: 20px;
}
    .starRating {
        font-size: 3rem;
    }
    .avgtxt {
        font-size: 0.8rem;
    }
    .actionButton {
        gap: 1rem;
        width: 100%;
    }
    .actionButton button {
        width: 100%;
        padding: 1rem;
        font-size:1rem;
    }
    .review_ndate{
        font-size: 0.9rem;
    }
    .name{
        font-size: 0.9rem;
    }
    .review_text{
        font-size: 0.9rem;
        margin-top: 0.5rem;
        line-height: 20px;
    }
  
  .listrapper {
        flex-direction: column;
        gap: 1rem;
    }
    .review_summary>div {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0.5rem;
    }
    .review_summary>div>h3 {
        font-size: 1.5rem;  
    }
    .reviewhelpful {
        padding-inline: 10px;
        padding-block: 10px;
    }
    .reviewhelpful>p {
        font-size: 0.9rem;
    }
    .helpfulbuttons {
        gap: 0.5rem;
    }
    .thumbsupimg, .thumbsdownimg{
          width: 20px;
          height: 20px;
    }
    .thumbsup,.thumbsdown {
        gap: 0.6rem;
    }
    .thumbsup>span,.thumbsdown>span {
        font-size: 0.8rem;
    }
    .view_all>a{
        font-size: 1.3rem;
        border-bottom: 1px solid #222222;
    }
    
}