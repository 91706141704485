@import '/styles/_global';
.overviewcontainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: clamp(2.5rem, 3.125vw, 3.75rem);
    grid-row-gap: 10px;
    margin-top: clamp(3.5rem, 4vw, 5rem);
    margin-bottom: clamp(3.5rem, 4vw, 5rem);
    background: #fff;
    max-height: clamp(38.25rem,44vw,53.75rem);
    
    .overviewcontent{
         margin: auto;
         padding-left: clamp(3.5rem, 4vw, 5rem);
         padding-top: 1rem;
         max-height: clamp(38.25rem,44vw,53.75rem);
         overflow-y: auto;

         h2{
        font-family: "Bell MT";
        font-size: clamp(1.5rem, 1.5vw, 2rem);
        color: #3e4245;
        margin-bottom: 1.25rem;
        }
        p{
            font-family: "Barlow";
            line-height: 25px;
            font-weight: 400;
            color: #3e4245;
            font-size: clamp(0.85rem,1vw,1.125rem);
        }
    }

    .imageWrapper{
        max-height: clamp(38.25rem,44vw,53.75rem);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }
    }
}

@media screen and (min-width: 541px) and (max-width: 992px) {

    .overviewcontainer{
        display: flex;
        flex-direction: column-reverse;
        max-height: fit-content;
        padding-top: 3rem;

        .overviewcontent{
            padding-block: 20px;
            padding-left: 0;
            margin-top: 7rem;
            margin-bottom: 2rem;
            padding: 1rem;
            
            h2{
                font-size: 6rem;    
                text-align: center;
            }
            p{
                font-size: 2.5rem;
                line-height: 60px;
                text-align: center;
            }
        }
    }
}

@media screen and (min-width: 290px) and (max-width: 540px) {
    .overviewcontainer{
        display: flex;
        flex-direction: column-reverse;
        max-height: fit-content;
        
        .overviewcontent{
            padding-block: 20px;
            padding-left: 0;
            margin: 1rem;

            h2{
                font-size: 3rem;
                text-align: center;
            }
            p{
                font-size: 1rem;
                line-height: 24px;
                text-align: center;
            }
        }
    }
}