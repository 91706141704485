@import '/styles/_global';
.product_tag {
  font-family: "Barlow";
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #3e4245;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.qtyParent{
  display: flex;
  padding-top: 14px;
  font-family: "Barlow";
  font-size: 1rem;
  font-weight: 500 !important;

  .plusMinsContainer{
    width: auto;
  }

  abbr{
    font-weight: 600 !important;
    color: #3e4245 !important;
  }
}

.info_col {
  min-width: 50%;
  padding: 55px 50px;
  // background-color: #fbdd9e;
}

.pdp_sec1 {
  display: inline-block;
  width: 100%;
}

.pdp_sec1 h1 {
  font-family: "Bell MT";
  font-weight: 400;
  font-size: clamp(1.85rem,2.1875vw,2.625rem);
  line-height: 3.25rem;
  color: #3e4245;
  margin-bottom: 0.5rem;
}

.product_sht_desc {
  font-family: "Barlow";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #000;
  margin-bottom: 1rem;
}

.star_rating_share {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.star_rating {
  display: flex;
  gap: 10px;
  align-items: center;
}

.star_rating_text {
  font-family: "Barlow";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #3e4245;
  text-decoration: underline !important;
  cursor: pointer;
}

.origin {
  font-family: "Barlow";
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #000000;
  margin-bottom: 0.5rem;

  span {
    font-weight: 400;
  }
}

.pwa_desc p {
  font-family: "Barlow";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #3e4245;
  margin-bottom: 1.5rem;
}

.pdp_priceSec {
  display: flex;
  gap: 16px;
}

.pdp_price {
  display: flex;
  gap: 50px;
  font-family: "Barlow";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #3e4245;
  }

  .inclPrice {
    font-family: "Barlow";
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.pdp_sec3 {
  margin-top: 6px;
}

.h5pdp_quantity {
  display: flex;
  gap: 5px;
  font-family: "Barlow";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #3e4245;
  margin-bottom: 12px;
}

.pdp_sec3 .active {
  border: 2px solid #000;
  border-radius: 4.5px;
  padding: 10px 0px;
  width: 70px;
  font-family: "Barlow";
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  background-color: transparent;
  color: #000;
  display: block;
  text-align: center;
  cursor: pointer;
}

.pdp_sec3 .active:hover {
  color: #000;
}

.nonactive {
  border: 1px solid #575757;
  border-radius: 4.5px;
  padding: 10px 0px;
  width: 70px;
  font-family: "Barlow";
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  background-color: transparent;
  color: #575757;
  display: block;
  text-align: center;
  cursor: pointer;
}

.nonactive:hover {
  color: #575757;
}

.outOfStock {
  position: relative;
  display: inline-block;
  border: 1.81px solid #575757;
  border-radius: 4.5px;
  padding: 10px 0px;
  width: 70px;
  font-family: "Barlow";
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  background-color: transparent;
  color: #575757;
  text-align: center;
}

.outOfStock:hover {
  color: #575757;
}

.outOfStock::before {
  content: "";
  position: absolute;
  top: 44%;
  left: 7%;
  width: 77px;
  height: 100%;
  border-top: 1px solid #575757;
  transform: rotate(-30deg);
  cursor: not-allowed;
}

.productvariants {
  display: flex;
  gap: 20px;
}

.pdp_sec2 {
  margin-top: 24px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.pdp_sec2 li button {
  width: 100%;
  font-size: 1rem;
  color: #fff;
  display: block;
  font-family: "Barlow";
  font-weight: 600;
  padding: 18px 0;
  position: relative;
  letter-spacing: 1.25px;
  line-height: 1.25rem;
  background: #3e4245;
}

.pdp_sec2 li {
  width: 100%;
  text-align: center;
}

.snapMint_sec {
  display: flex;
  align-items: center;
  gap: 14px;
}

.emi_sec {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 10px;
  border: 1px solid #222222;
  border-radius: 4px;
  margin-top: 24px;
}

.pdp_sec4 {
  margin-top: 32px;
}

.estd_delv_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estd_delv {
  display: flex;
  width: 45%;
  gap: 6px;
  align-items: baseline;
}

.input_check {
  border-bottom: 1px solid #000;
}

.input_check form {
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
}

.input_check button {
  min-width: 70px;
  text-align: center;
  background-color: transparent;
  font-family: "Barlow";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #000;
  text-transform: capitalize;
}

.input_check input {
  border: 0;
  display: inline-block;
  width: 78%;
  background-color: transparent !important;
  font-family: "Barlow";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #000;
}

.h5pdp_pincode {
  font-family: "Barlow";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #000;
}

.Pincode_text {
  font-family: "Barlow";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #000;
  margin-top: 24px;
  margin-bottom: 0;
}

.delivery_success {
  font-family: "Barlow";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: green;
}
.delivery_error {
  font-family: "Barlow";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #ad3132;
}

.h6pdp_pincode {
  font-family: "Barlow";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #313131;
}

.plusMinusParent{
  top: 0;
}

@media (max-width: 992px) {
  .qtyParent{
    display: flex;
    padding-top: 14px;
    font-family: "Barlow";
    font-size: 1rem;
    font-weight: 500 !important;
  
    .plusMinsContainer{
      width: auto;
    }

    .plusMinusParent{
      display: flex;
    }
  
    abbr{
      font-weight: 600 !important;
      color: #3e4245 !important;
      margin-right: 1rem;
    }
  }

  .info_col {
    min-width: 100%;
    padding: 61.05px 40.7px;
  }

  .product_tag {
    font-size: 1.91rem;
    line-height: 2.54rem;
    margin-bottom: 2.54rem;
    color: #000;
  }

  .pdp_sec1 h1 {
    font-size: 5.09rem;
    line-height: 6.36rem;
    color: #000;
    margin-bottom: 0.67rem;
  }

  .product_sht_desc {
    font-size: 2.23rem;
    line-height: 2.86rem;
    margin-bottom: 2.54rem;
  }

  .star_rating_share {
    gap: 101.74px;
    margin-bottom: 3.82rem;
  }

  .star_rating {
    gap: 20.35px;
  }

  .star_rating_text {
    font-size: 2.23rem;
    line-height: 2.54rem;
  }

  .origin {
    font-size: 2.23rem;
    line-height: 3.18rem;
    margin-bottom: 3.82rem;
  }

  .pwa_desc p {
    font-size: 2.23rem;
    line-height: 3.18rem;
    margin-bottom: 0;
  }

  .pdp_priceSec {
    gap: 16px;
  }

  .pdp_price {
    gap: 182px;
    font-size: 2.23rem;
    line-height: 2.86rem;

    span {
      font-size: 2.23rem;
      line-height: 2.86rem;
    }
  }

  .inclPrice {
    font-size: 1.91rem;
    line-height: 2.86rem;
    white-space: nowrap;
  }

  .pdp_sec3 {
    margin-top: 30.52px;
  }

  .h5pdp_quantity {
    gap: 65px;
    font-size: 2.23rem;
    line-height: 2.86rem;
    margin-bottom: 81.39px;
  }

  .productvariants {
    gap: 0px;
    justify-content: space-between;
  }

  .pdp_sec3 .active {
    padding: 22.94px 0px;
    width: 22.581vw;
    font-size: 2.29rem;
    line-height: 2.86rem;
  }

  .nonactive {
    padding: 22.94px 0px;
    width: 22.581vw;
    font-size: 0.9rem;
    line-height: 2.86rem;
  }

  .outOfStock {
    padding: 22.94px 0px;
    width: 22.581vw;
    font-size: 2.29rem;
    line-height: 2.86rem;
  }

  .outOfStock::before {
    content: "";
    position: absolute;
    top: 46%;
    left: 5%;
    width: 23.79vw;
    height: 100%;
    border-top: 1px solid #575757;
    transform: rotate(-22deg);
  }

  .pdp_sec2 {
    margin-top: 81.39px;
  }

  .pdp_sec2 li button {
    font-size: 2.54rem;
    padding: 45.78px 0;
    line-height: 3.18rem;
  }

  .snapMint_sec {
    align-items: start;
    gap: 70.94px;
  }

  .snapMint_sec > img:first-child {
    width: 164px;
    height: 76.31px;
  }

  .emi_sec {
    padding: 30.52px;
    gap: 15.49px;
  }

  .pdp_sec4 {
    margin-top: 85.24px;
  }

  .estd_delv_sec {
    flex-direction: column;
    gap: 40.7px;
    justify-content: start;
    align-items: start;
  }

  .estd_delv {
    width: 100%;
    gap: 15.26px;
  }

  .estd_delv img {
    width: 30.52px;
    height: 35.61px;
  }

  .input_check {
    width: 100%;
  }

  .input_check form {
    gap: 0px;
    justify-content: space-between;
  }

  .input_check button {
    text-align: right;
    font-size: 2.54rem;
    line-height: 3.82rem;
    padding: 0 !important;
  }

  .input_check input {
    font-size: 2.23rem;
    line-height: 3.18rem;
  }

  .h5pdp_pincode {
    font-size: 2.23rem;
    line-height: 2.86rem;
  }

  .Pincode_text {
    font-size: 2.23rem;
    line-height: 3.18rem;
    margin-top: 40.7px;
  }

  .delivery_success {
    font-size: 2.23rem;
    line-height: 2.86rem;
  }
  .delivery_error {
    font-size: 2.23rem;
    line-height: 2.86rem;
  }

  .h6pdp_pincode {
    font-size: 2.23rem;
    line-height: 2.86rem;
  }
}

@media (max-width: 767px) {
  .info_col {
    padding: 24px;
  }

  .product_tag {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 1rem;
  }

  .pdp_sec1 h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0.25rem;
  }

  .product_sht_desc {
    font-size: 0.875rem;
    line-height: 1.13rem;
    margin-bottom: 1rem;
  }

  .star_rating_share {
    gap: 40px;
    margin-bottom: 1.5rem;
  }

  .star_rating {
    gap: 8px;
  }

  .star_rating_text {
    font-size: 0.875rem;
    line-height: 1.13rem;
  }

  .origin {
    font-size: 0.875rem;
    line-height: 1.13rem;
    margin-bottom: 1.5rem;
  }

  .pwa_desc p {
    font-size: 0.875rem;
    line-height: 1.13rem;
    // margin-bottom: 1.5rem;
  }

  .pdp_priceSec {
    gap: 10px;
  }

  .pdp_price {
    gap: 72px;
    font-size: 0.875rem;
    line-height: 1.13rem;

    span {
      font-size: 0.875rem;
      line-height: 1.13rem;
    }
  }

  .inclPrice {
    font-size: 0.75rem;
    line-height: 1.13rem;
  }

  .pdp_sec3 {
    margin-top: 12px;
  }

  .h5pdp_quantity {
    gap: 25px;
    font-size: 0.875rem;
    line-height: 1.13rem;
    margin-bottom: 32.02px;
  }

  .pdp_sec3 .active {
    padding: 9px 0px;
    width: 22.454vw;
    font-size: 0.9rem;
    line-height: 1.13rem;
    white-space: nowrap;
  }

  .nonactive {
    padding: 9px 0px;
    width: 22.454vw;
    line-height: 1.13rem;
    white-space: nowrap;
  }

  .outOfStock {
    padding: 9px 0px;
    width: 22.454vw;
    font-size: 0.9rem;
    line-height: 1.13rem;
    white-space: nowrap;
  }

  .outOfStock::before {
    content: "";
    position: absolute;
    top: 46%;
    left: 5%;
    width: 23.79vw;
    height: 100%;
    border-top: 1px solid #575757;
    transform: rotate(-22deg);
  }

  .pdp_sec2 {
    margin-top: 32px;
  }

  .pdp_sec2 li button {
    font-size: 1rem;
    padding: 18px 0;
    line-height: 1.25rem;
    background: #3e4245;
  }

  .snapMint_sec {
    align-items: center;
    gap: 8.82px;
  }

  .snapMint_sec > img:first-child {
    width: 67px;
    height: 30px;
  }

  .emi_sec {
    padding: 13px;
    gap: 11px;
    margin-top: 32px;
  }

  .pdp_sec4 {
    margin-top: 32px;
  }

  .estd_delv_sec {
    gap: 18px;
  }

  .estd_delv {
    width: 100%;
    gap: 6px;
  }

  .estd_delv img {
    width: 12px;
    height: 14px;
  }

  .input_check button {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .input_check input {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .h5pdp_pincode {
    font-size: 0.875rem;
    line-height: 1.13rem;
  }

  .Pincode_text {
    font-size: 0.875rem;
    line-height: 1.13rem;
    margin-top: 16px;
  }

  .delivery_success {
    font-size: 0.875rem;
    line-height: 1.13rem;
  }
  .delivery_error {
    font-size: 0.875rem;
    line-height: 1.13rem;
  }

  .h6pdp_pincode {
    font-size: 0.875rem;
    line-height: 1.13rem;
  }
}
