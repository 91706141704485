@import '/styles/_global';
.reviewform .inputrow {
    padding-left: 0;
    padding-right: 0;
}


.reviewform h4 {
    font-family: 'Barlow';
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #AD3132;
    font-weight: 600;
    display: block;
    font-size: 13px;
}

.reviewform h5 {
    letter-spacing: 0.15px;
    font-size: 19px;
    color: #000000;
    font-weight: 500;
    font-family: Cormorant Garamond;
    line-height: 1.5;
    padding-bottom: 10px;
}

.inputstar svg {
    font-size: 35px;
}

.reviewform .inputcolm fieldset,
.reviewform .inputcolmtext fieldset {
    border-radius: 0;
    border: 0;
}

.reviewform .inputcolm input {
    font-size: 14px;
    padding: 8px 0px;
    letter-spacing: 0.5px;
    font-weight: 600;
    border-bottom: 1px solid #F0F0F0;
    background-color: #FFFFFF;
}

.reviewform .inputcolm label,
.reviewform .inputcolmtext label {
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transform: inherit;
    position: relative;
    color: #AD3132;
    display: block;
    padding-bottom: 2px;
   
}

.reviewform .inputcolm {
    padding-bottom: 25px;
}

.inputcolmtext textarea {
    font-size: 14px;
    padding: 12px 15px;
    letter-spacing: 0.5px;
    min-height: 130px;
    font-weight: 600;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #FFFFFF;
}

.reviewform .inputcolmtext div {
    padding: 0;
}

.reviewform .white_btn button {
    padding: 12px 30px;
    width: auto;
    float: right;
    background: #3E4245;
    color: #FFFFFF;
    font-family: "Barlow";
    font-size: 1rem;
    font-weight: semibold;
    margin-top: 10px;
}

.reviewform input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    box-shadow: 0 0 0px 1000px white inset;
    font-size: 14px;
}

.reviewform h3 {
    font-size: 33px;    
    padding-bottom: 15px;
    line-height: 1;
    letter-spacing: 0.25px;
    border-bottom: 1px solid #F0F0F0;
    margin-bottom: 30px;
    font-family: "Bell MT";
    font-weight: 500;
    margin-top: 20px;
}

.reviews_product {
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgb(49 49 49 / 16%);
    display: inline-block;
    position: relative;
    padding-left: 90px;
    min-height: 100px;
    padding: 10px;
    padding-left: 100px;
    max-width: 330px;
    margin-bottom: 20px;
}

.reviews_product span {
    width: 77px;
    height: 77px;
    padding: 5px;
    border-radius: 5px;
    left: 10px;
    position: absolute;
    background: #eeeeee;
}
.reviews_product span image {
    width: 77px !important;
    height: 77px !important;
}

.reviews_product h6 {
    letter-spacing: 0.15px;
    font-size: 19px;
    font-family: "Bell MT";
    font-weight: 500;
    padding-bottom: 10px;
}

.reviews_product h4 {
    font-family: "Barlow";
    font-size: 16px;
    font-weight: 600;
}


.inputstar svg {
    font-size: 35px;
}

.reviews_rating li {
    font-size: 1rem;
    border: 0;
    margin: 0;
    padding: 0;
    display: flex ;
    align-items: center;
    gap: 1.2rem;
    font-family: "Barlow";
}

.reviews_rating .star_rating {
    font-size: 16px;
    display: inline-block;
    margin-right: 25px;
    float: none; 
}

.reviews_rating {
    padding-top: 15px;
}


@media (max-width: 767px) {
    .reviewform h3 {
        font-size: 22px;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 15px;
    }

    .reviewform h5 {
        font-size: 19px;
        padding-bottom: 10px;
    }

    .reviewform .white_btn button {
        background: #3E4245;
        color: #fff;
        width: 100%;
    }

    .inputstar svg {
        font-size: 35px;
    }
    .inputstar {
        padding-bottom: 10px;
    }

    .reviews_rating {
        padding-top: 0px;
        padding-bottom: 20px;
    }
}