@import '/styles/_global';
.pdp_sec14{ margin-top:60px;display: inline-block; width: 100%;}
.pdp_sec14 .row{flex-wrap: nowrap; white-space: nowrap; overflow: auto;-webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;-ms-overflow-style: none; scrollbar-width: none;-webkit-transform: translateZ(0);
     transform: translateZ(0); scroll-behavior: smooth; display: flex; margin: 0 -8px;}
     .pdp_sec14 ::-webkit-scrollbar{ width: 0; height: 0;}
     .pdp_sec14 ul li{ white-space:normal; padding: 0 12px;margin-top: 10px; margin-bottom: 15px; }
     .pdp_sec14 .h2pdp_sec14{ font-family: 'Bell MT'; color: #3E4245;  font-size: 2.40rem; letter-spacing: 0.25px; margin-bottom:2.12rem; text-transform: capitalize; line-height: 2.97rem; font-weight: 400;text-align: center; }
     .pdp_sec14 ul{overflow: inherit;}
      .pdp_product_scroll{
    flex-wrap: nowrap;
    overflow: inherit;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        scroll-behavior: smooth;
        margin: 0 -8px;
    }
    .carousel_item_padding{padding:0 12px;}
    .kama_journal_box {  padding: 20px; border-radius: 5px;    height: 100%;}		
.kama_journal_box span {display: block;margin-bottom: 15px;overflow: hidden;   border-radius: 0px;}		
	
.kama_journal_box span img{ display: block;height: auto; min-height: 100%; max-height: 100%;}
.pdp_sec14 .col p{font-family:'Barlow' ;font-weight:400; color: #575757; letter-spacing: 0px;font-size: 0.92rem;line-height: 1.37rem; margin-bottom: 7px;min-height: 65px;}
.pdp_sec14 .col a{ font-family: 'Rupee Foradian';   letter-spacing: 0px;text-decoration: underline !important; color: #3E4245; font-weight: 400; line-height: 1.37rem;
    display: block; font-size: 0.92rem;}
   
    .kama_journal_box h4{font-family: 'Bell MT'; font-size: 1.14rem; line-height: 1.6rem;  font-weight: 500;  padding-bottom: 0.46rem;color: #3E4245;}



@media (max-width: 992px){
    .pdp_sec14{margin-top:1.88rem;}
    .pdp_sec14 ul li{ padding: 0 0.44rem;}
    .pdp_sec14 .h2pdp_sec14{font-size: clamp(32px, 8.2vw, 81.39px); line-height: clamp(40px, 8.2vw, 101.74px); margin-bottom: 1.50rem;}
    .pdp_sec14 .pdp_product_scroll .col-9{ padding: 0 0.44rem; white-space: normal; }
    .pdp_sec14 .pdp_product_scroll{ white-space: nowrap;   padding-top: 0.63rem;padding-bottom: 0.94rem;}
    .pdp_product_scroll{ overflow-x: scroll;  margin: 0 -0.44rem;}
    .kama_journal_box{ padding: 0.63rem;}
    .kama_journal_box h4 {  font-size: clamp(20px, 5vw, 50.87px);letter-spacing: 0.15px; line-height: clamp(28px, 6.5vw, 71.22px); font-weight: 500; padding-bottom: clamp(7.86px, 1.5vw, 20px);}
    .pdp_sec14 .col p{
        font-size: clamp(14px, 3vw, 28px); 
        line-height: clamp(20px, 4vw, 42px);
        min-height: clamp(65px, 9vw, 90px);
        margin-bottom: clamp(7.86px, 2vw, 20px);
    }
    .pdp_sec14 .col a {
        font-size: clamp(14px, 3vw, 28px); 
        line-height: clamp(20px, 4vw, 42px);

    }
    .kama_journal_box span{ border-radius: 5px; margin-bottom: 0.63rem;}

}

