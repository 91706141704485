@import '/styles/_global';
.saffron_sec {
  width: 100%;
  height: 100%;
  margin-bottom: clamp(3.5rem, 4vw, 5rem);

  h3{
    font-family: 'DINMedium';
    font-size: clamp(4rem,4.65vw,5.625rem);
    line-height: clamp(4rem,4.65vw,5.625rem);;
    font-weight: 500;
    text-transform: uppercase;
    color: #3E4245;
    text-align: center;
  }

  h4{
    font-family: 'DINMedium';
    font-size: clamp(3rem,3.5vw,4.25rem);
    line-height: clamp(4rem,4.65vw,5.625rem);;
    font-weight: 500;
    text-transform: uppercase;
    color: #3E4245;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 3.75rem;
  }

  img{
    width: 100%;
    height: 100%;
  }
}
.saffron_sec_desc {
  padding: 16rem 4.58rem 0rem 0rem;
}

.saffron_sec_desc p {
  font-family: "DIN";
  font-weight: 400;
  font-size: clamp(28px, 2.38vw, 45.76px);
  line-height: clamp(36px, 2.85vw, 54.91px);
  color: #3e4245;
}

@media (max-width: 992px) {
  .saffron_sec {
      h3{
        font-size: 2rem;
        line-height: clamp(34px, calc(34px + (86.48 - 34) * ((100vw - 360px) / (992 - 360))), 86.48px);
      }

      h4{
        font-size: 1.5rem;
        line-height: clamp(34px, calc(34px + (86.48 - 34) * ((100vw - 360px) / (992 - 360))), 86.48px);
        margin-bottom: clamp(31.5px, calc(34px + (86.48 - 34) * ((100vw - 360px) / (992 - 360))), 40px);;
      }
  }

  .saffron_sec_desc {
    padding: 0rem 5.21rem 0rem 5.21rem;
  }

  .saffron_sec_desc p {
    font-size: 45.78px;
    line-height: 55.96px;
  }
}

@media (max-width: 767px) {
  .saffron_sec_desc {
    padding: 0rem 2.03rem 0rem 2.03rem;
  }

  .saffron_sec_desc p {
    font-size: 18px;
    line-height: 22px;
  }
}
