@import '/styles/_global';
.product_faq_ques{width: 100%; color: #222222 !important; font-size: 3.18rem;  font-weight: 400;padding-top: 3.13rem !important; padding-bottom:1.27rem !important;font-family: 'Bell MT';  letter-spacing: 0px; line-height: 4.45rem;}
.product_faq_ans{ padding-bottom: 3.34rem !important; display: block !important;border-bottom: 1px solid rgba(204, 204, 204, 0.5) !important;
    margin-top: -2px !important; background: #fff;position: relative; font-family: 'Barlow'; font-size: 2.23rem;color: #575757; font-weight: 400; letter-spacing: 0px;line-height: 3.18rem; text-align: justify;}
.pdp_sec13{margin-top:30px; display: inline-block; width: 100%;}
.pdp_sec13 .h2pdp_sec13{font-family: 'Bell MT';color: #222222;font-size: 2rem; line-height: 2.50rem; padding-bottom: 3.18rem; text-align: center;font-weight: 400;border-bottom: 1px solid rgba(204, 204, 204, 0.5)}	
.view_all a {font-family: 'Bell MT';  letter-spacing: 0px; color:#222222;  font-weight: 400;  display: block;font-size: 3.18rem; line-height: 4.45rem; text-decoration: underline !important; text-align: center;}  
.pdp_sec13 .view_all a { margin-top: 3.82rem ;}
.pdp_sec13 .accordion { margin-bottom: 20px;}
.pdp_sec13 .accordion_item { border-radius: 0; border: 0; margin: 0;}		
.pdp_sec13 .accordion_item h3 a { position: relative;display: block; width: 100%; font-size: 14px; font-weight: 600;padding: 17px 0; border-bottom: 1px solid #f0ddd1;font-family: 'Open Sans';letter-spacing: 0.25px;padding-right: 30px;}
.pdp_sec13 .accordion_item h3 a:before {
content: "";position: absolute; background-image: url("/public/images/drop-down-arrow.inline.svg"); width: 28px; height: 28px; right: 0;-webkit-transition: all 0.32s ease-out; -moz-transition: all 0.32s ease-out;-o-transition: all 0.32s ease-out; transition: all 0.32s ease-out;     top: 13px;}
.pdp_sec13 .accordion_item h3 a.collapsed:before {
content: "";position: absolute; background-image: url("/public/images/drop-down-arrow.inline.svg"); width: 28px; height: 28px; right: 0;transform: rotate(180deg); }
.pdp_sec13 .accordion_body {padding: 20px 0;}	
.pdp_sec13 .accordion_body ul li {font-size: 14px;}
.pdp_sec13 .accordion_body p{letter-spacing: 0.25px;}		
.pdp_sec13 .accordion_body p:last-child { margin: 0;}


@media (min-width: 992px){
    .pdp_sec13{ margin-top: 60px; background: #fff; padding-left: clamp(8.5rem,10vw,12rem); padding-right: clamp(8.5rem,10vw,12rem); padding-top: clamp(1.75rem,2vw,2.5rem); padding-bottom: clamp(1.75rem,2vw,2.5rem);}
    .pdp_sec13 .h2pdp_sec13{font-size: clamp(1.875rem, 2.2vw, 2.625rem); letter-spacing: 0px; padding-bottom: 2.29rem; line-height: 2.97rem; }
    .product_faq_ques{font-size: 1.60rem; line-height: 2.06rem; padding-top: 2.29rem !important; padding-bottom:0.46rem !important;}
    .product_faq_ans{ font-size: 0.92rem; line-height: 1.37rem;   padding: 0rem 0 2.29rem !important;}
    .view_all a{ font-size: 1.14rem; line-height: 1.60rem;}
    .pdp_sec13 .view_all a { margin-top: 3.66rem;}
}

@media (max-width: 767px){
    .pdp_sec13{margin-top:15px; padding: 1rem; }
    .product_faq_ques{font-size: 1.25rem; line-height: 1.75rem; padding-top: 1.25rem !important; padding-bottom: 0.50rem !important;}
    .product_faq_ans{font-size: 0.88rem; line-height: 1.25rem; padding-bottom: 1.25rem !important;}
    .pdp_sec13 .h2pdp_sec13{padding-bottom: 1.25rem; }
    .view_all a{ font-size: 1.25rem; line-height: 1.75rem;}
    .pdp_sec13 .view_all a { margin-top: 1.50rem; margin-bottom: 4rem;}
}