@import '/styles/_global';
.pwa_desc_container {
  position: relative;
  max-width: 400px;
}

.pwa_desc {
  overflow: hidden;
  transition: max-height 0.3s ease;
  line-height: 1.5em; /* Ensure a consistent line height */
}

.collapsed {
  max-height: calc(1.5em * 3); /* Exactly 3 lines */
}

.expanded {
  max-height: none;
}

.read_more {
  font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.675rem;
    color: #3E4245;
    text-decoration: underline !important;
  cursor: pointer;
  background: none;
  border: none;
  padding: 5px 0;
}
