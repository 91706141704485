@import '/styles/_global';
.h2pdp_sec11{font-family: 'Bell MT';font-size: 2.4rem; letter-spacing: 0px; margin-bottom: 20px;  text-transform: capitalize;
    line-height: 2.97rem; font-weight: 400; text-align: center;}   
    // .pdp_sec14 ul{ overflow: inherit;}
    .pdp_product_scroll{
        flex-wrap: nowrap;
        overflow: inherit;
            -webkit-overflow-scrolling: touch;
            overflow-scrolling: touch;
            -ms-overflow-style: none;
            scrollbar-width: none;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            scroll-behavior: smooth;
            margin: 0 -8px;
        }
        .relatedProductPDP{
            flex-direction: column;
        }
        .carousel_item_padding{padding: 0px 12px;}

@media (max-width: 992px){
        .h2pdp_sec11{font-size: clamp(32px, 8.2vw, 81.39px); line-height: clamp(40px, 8.2vw, 101.74px); text-align: center;padding-bottom: 10px;}
        .pdp_product_scroll{ overflow-x: scroll;margin: 0 -7px;}
        .pdp_sec11 .pdp_product_scroll .col-9{ padding: 0 7px;white-space: normal; }
        .carousel_item_padding{padding-right:clamp(7px, 8.2vw, 12px);}
}