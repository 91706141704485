@import '/styles/_global';
.heading_apply {
    font-family: "Bell MT";
    font-weight: 400;
    text-align: center;
    font-size: clamp(4rem,4.5vw,5.6rem);
    margin-bottom: clamp(2.5rem, 3vw, 3.25rem);
}


.applycontainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: clamp(2.5rem, 3.125vw, 3.75rem);
    padding-left: clamp(3.5rem, 4vw, 5rem);
    margin: auto;
    margin-bottom: clamp(3.5rem, 4vw, 5rem);
    align-items: center;
    background-color: #ffffff;
    .descontainer {
        padding-left: 1rem;

        h3 {
            font-size: clamp(1.5rem,1.66vw,2rem);
            font-weight: 400;
            margin-block: 1rem;
            color: #3E4245;
        }

        ul {
            list-style: disc;
            padding-left: 2.25rem;
            font-family: 'Barlow';
            margin-bottom: 2.5rem;

            li {
                font-size: clamp(0.75rem,1vw,1.125rem);
                font-weight: 400;
                color: #3E4245
            }
        }
    }
}

@media screen and (max-width:992px) {
    .heading_apply {
        font-size: 4.75rem;
    }

    .applycontainer {
        display: flex;
        flex-direction: column-reverse;

        .descontainer {
            padding-left: 2rem;

            h3 {
                font-size: 3rem;
            }

            ul {

                li {

                    font-size: 2.5rem;
                }
            }
        }
    }

}


@media screen and (max-width:540px) {
    .heading_apply {
        font-size: 2.625rem;
    }

    .applycontainer {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 0;

        .descontainer {
            padding-left: 0;

            h3 {
                font-size: 1.2rem;
            }

            ul {
                li {
                    font-size: 1.1rem;
                }
            }
        }
    }

}