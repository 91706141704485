@import '/styles/_global';
.teaserBannerParent{margin-top: 13rem; position: relative; display: flex;justify-content: center; }
.teaserWithoutTimerParent{margin-top: 9.25rem; position: relative; display: flex;justify-content: center; align-items: center; }
.teaserBannerParent video{width: 100%; height: 28.646vw; object-fit: cover; }
.teaserBanner { width: 100%; height: auto; display: block; }
.teaserBannerText {position: absolute; width: 100%; top: 50%;left: 50%; transform: translate(-50%, -50%); }
.teaserBannerHead{font-family: 'DIN Condensed'; font-weight: 500; font-size: 100px; color: #fff; text-align: center; margin-bottom: 0px !important;}
.teaserBannerDate{font-family: 'DIN Condensed'; font-weight: 600; font-size: 60px; color: #fff; text-align: center;margin-bottom: 0px !important;}
  
@media (max-width: 992px){
.teaserBannerHead{font-size: 1.875rem; text-align: center;}
.teaserBannerDate{font-size: 1.275rem; text-align: center;}
.teaserBannerParent{
    margin-top: 12.25rem;

    video{
        height: clamp(450px, calc(450px + (1240 - 450) * ((100vw - 360px) / (992 - 360))), 1240px);
    }
}
}