@import '/styles/_global';
.offersrewards {
    padding-top: clamp(4.5rem,5.2vw,6.25rem);
    padding-bottom: clamp(4.5rem,5.2vw,6.25rem);
    margin-bottom: clamp(3.5rem,4vw,5rem);
    background: #fff;

    .offerParent{
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;
        max-width: 1720px;
        padding-right: var(--bs-gutter-x, .75rem);
        padding-left: var(--bs-gutter-x, .75rem);
        margin: auto;
    }

    .boxoffers,.boxrewards {
        width: 100%;
        border: 1px solid #AD3132;
        padding: clamp(2rem,2.25vw,2.8rem);
        background: #fff;
    }

    h2 {
        font-size: 2rem;
        color: #3E4245;
        margin-bottom: 1rem;
        font-family: "Bell MT";
        font-weight: 400;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            font-family: "Barlow";
            display: flex;
            gap: 0.5rem;
            font-size: 1rem;
            margin-bottom: 0.5rem;
            color: #575757;
            img{
            width: 13px;
            height: 22px;
            }
        }
    }
    a {
        font-size: 1rem;
        font-family: "Barlow";
        color: #575757;
        text-decoration: underline !important;
        padding-bottom: 2px;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
.modalbody{
     background-color: #fff;
     width: 80%;
     margin: auto;
     margin-block: 100px;
     padding: 50px;
     position: relative;
     font-family: "Barlow";
     font-weight: 400;
     overflow: auto;
}

.close{
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
}

@media screen and (min-width: 993px) and (max-width: 1720px) {
    .offersrewards {
        .offerParent{
            max-width: 1050px;
        }
    }
}

@media screen and (min-width: 541px) and (max-width: 992px) {
    .offersrewards {
        flex-direction: column;
        gap: 1rem;
        padding: 0.5rem;

        .offerParent{
            flex-direction: column;
            gap: 1rem;
        padding: 0.5rem;
        }

        .boxoffers,.boxrewards{
            border: none;
        }
    
        h2 {
            font-size: 5rem;
        }
    
        ul {
           
            li {
                font-size: 3rem;
                img{
                width: 36px;
                height: 59px;
                }
                line-height: 68px;
            }
        }
        a {
            font-size: 1.1rem;
        }
    }
    .modalbody{
        width: 100dvw;
        height: 100dvh;
        margin: auto;
   }
}

// Responsive for screens **390px and below**
@media screen and (min-width: 290px) and (max-width: 540px) {
    .offersrewards {
        flex-direction: column;
        gap: 1rem;
        padding: 0.5rem;

        .offerParent{
            flex-direction: column;
            gap: 1rem;
        padding: 0.5rem;
        }

        .boxoffers,.boxrewards{
            border: none;
        }

        h2 {
            font-size: 1.8rem;
        }
    
        ul {
           
            li {
                font-size: 1.1rem;
                font-weight: 400;
                img{
                width: 14px;
                height: 23px;
                }
                line-height: 28px;
            }
        }
        a {
            font-size: 1.1rem;
        }
    }  
    .modalbody{
        width: 100dvw;
        height: 100dvh;
        margin: auto;
        overflow-y: scroll !important;
    }
}