.button {
  border: 0 none;
  font-size: 14px;
  color: #3e4245;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1.25px;
  margin: 0 auto;
  display: block;
  background: none;
}

.button:disabled {
  opacity: 0.5;
  cursor: unset;
}
